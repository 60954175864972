import React from "react"

const generateDays = (n: number): { [key: string]: Date[] } => {
  const d = new Date()
  const days = []

  for (let i = 0; i < n; i++) {
    days.push(new Date(d))
    d.setDate(d.getDate() + 1)
  }

  const chunks = {}

  days.forEach((d: Date) => {
    const key = "" + d.getFullYear() + ":" + d.getMonth()

    if (chunks[key]) {
      chunks[key].push(d)
    } else {
      chunks[key] = [d]
    }
  })

  return chunks
}

const monthName = (key: string) => {
  const i = parseInt(key.split(":")[1])

  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][i]
}

const year = (key: string) => parseInt(key.split(":")[0])

export default class HabitStreakPaper extends React.Component<
  {},
  {
    showGrid: boolean
    days: number
    portrait: boolean
    description: string
  }
> {
  state = {
    showGrid: false,
    days: 365,
    portrait: true,
    description: "",
  }

  render() {
    if (!this.state.showGrid) {
      return (
        <div className="form-column">
          <h1>Habit Streak Paper</h1>

          <p>I will...</p>

          <input
            id="description"
            type="text"
            onChange={evt => this.setState({ description: evt.target.value })}
            value={this.state.description}
            placeholder="Do at least 10 minutes exercise"
          />

          <p>For...</p>

          <div className="form-row">
            <input
              id="days"
              type="number"
              onChange={evt =>
                this.setState({ days: parseInt(evt.target.value, 10) })
              }
              value={this.state.days}
            />
            <label htmlFor="days">Days</label>
          </div>

          <div className="form-row">
            <input
              name="portrait"
              id="portrait"
              type="checkbox"
              checked={this.state.portrait}
              onChange={evt => this.setState({ portrait: evt.target.checked })}
            />
            <label htmlFor="portrait">Portrait</label>
          </div>
          <button onClick={this.generate}>Generate!</button>
        </div>
      )
    } else {
      const days = generateDays(this.state.days)
      console.log(days)

      return (
        <div className="page">
          <div style={{ paddingLeft: 10 }}>
            <h1>{this.state.description}</h1>
            <h4>Habit Streak Paper (from Amimetic)</h4>
          </div>

          <div
            className={`months-${
              this.state.portrait ? "portrait" : "landscape"
            }`}
          >
            {Object.keys(days).map(d => {
              return (
                <div className="month">
                  <h3>
                    {monthName(d)} {year(d)}
                  </h3>

                  <div className="days">
                    {this.padding(days[d][0].getDay())}
                    {days[d].map((dt, i) => (
                      <div className="day">
                        ☐ <span className="date-label">{dt.getDate()}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }

  generate = () => {
    this.setState({ showGrid: true })
  }

  padding = (dow: number) => {
    const n = this.paddingN(dow)
    const ar: number[] = []
    for (let i = 0; i < n; i++) {
      ar.push(i)
    }

    return ar.map(i => <div />)
  }

  paddingN = (dow: number) => {
    switch (dow) {
      case 1:
        return 0
      case 2:
        return 1
      case 3:
        return 2
      case 4:
        return 3
      case 5:
        return 4
      case 6:
        return 5
      case 0:
        return 6
    }
  }
}
